/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

:root {
	--blue: #4981F8;
	--blue-secondary: #7fa6fa;
	--light-blue: #207CFF1C;
	--bs-primary: #4981F8;
	--black: #00044B;
	--grey: #797979;
	--light-grey: #89898926;
	--blue-grey: #ACB5CF;
	--light-blue-grey: #ACB5CF80;
	--sky-blue: #D7ECFF;
	--text-secondary: #0000004D;
	--pink: #E1056D;
	--white: #FFFF;
	--light-black: #625F6E;
	--bg-light: #F7F7F7;
	--bg-screen: #FAFAFA;
}

.horizontal-layout.navbar-sticky .header-navbar {
	background-color: white !important;
	border-bottom: var(--light-grey) solid 1px;
}

.text-primary {
	color: var(--bs-primary) !important;
}

.bg-primary {
	background: var(--blue) !important;
}

.btn-primary {
	background: var(--bs-primary) !important;
	border-color: var(--bs-primary) !important;
}

.bg-transparent {
	background: transparent !important;
	border-color: transparent !important;
}

.w-40 {
	width: 40% !important;
}

.main-menu.menu-light {
	background-color: var(--white);
}

.main-menu.menu-light .navigation {
	background: none;
}

.vertical-layout .main-menu .navigation a.active {
	background: linear-gradient(118deg, var(--blue), var(--blue-secondary));
	box-shadow: 0px 0px 10px 0px var(--bs-primary);
}

.main-menu.menu-light .navigation li a {
	color: var(--light-black);
}

.dashboard-grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 5px;
	row-gap: 20px;
}

.dashboard-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 20px 20px;
	// gap: 28px;	

	margin-bottom: 20px;

	width: 100%;

	background: white;
	box-shadow: 0px 0px 30px 0px var(--light-grey);
	border-radius: 40px;
}

.dashboard-card-title {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: var(--grey);
}

.dashboard-card-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	margin-bottom: 10px;
}

.dashboard-card-label {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #00000080;
}

.dashboard-card-value {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 57px;
	letter-spacing: -0.015em;
	color: #000000CC;
}

.dashboard-card-total {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 8px 20px;
	width: 100%;
	background: var(--light-blue);
	border-radius: 20px;
}

.dashboard-leader-board {
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px;
	gap: 12px;
	background-color: white;
	width: 100%;
	min-height: 80vh;
	overflow-y: scroll;
	border: 1px solid var(--light-blue-grey);
	border-radius: 20px;
}

.separator hr {
	margin: 8px 0px 0px 0px;
}

.pointer {
	cursor: pointer;
}

/** Coverages CSS END */

.sidebar-modal {
	width: 50% !important;
}

.card-hovered {
	background-color: var(--light-blue-grey);
	/* Change to your desired hover background color */
}

.card-hoverable:hover {
	background-color: var(--light-grey);
	cursor: pointer;
}

.enlarge-image {
	transition: transform 0.3s ease-in-out;
}

.enlarge-image:hover {
	transform: scale(1.2);
	/* Adjust the scale value as desired */
}

/* Playlist */
.playlist-container {
	background-color: var(--bg-light);
	border: 1px solid var(--blue-grey) !important;
	border-radius: 8px;
	overflow: scroll;
	padding: 8px;
}

.playlist-container::-webkit-scrollbar {
	width: 4px;
	height: 5px;
	border-radius: 5px;
  }
  
//   .playlist-container::-webkit-scrollbar-track {
// 	// background:url('/assets/scroll-bg.png');
//   }
  
  .playlist-container::-webkit-scrollbar-thumb {
	background: var(--blue); /* Thumb color */
	border-radius: 10px; /* Rounded corners */
	height: 10px !important;
  }

.playlist-menu-item { 
	background: none;
	border-bottom: 1px dashed var(--blue-grey) !important;;
}
.playlist-menu-item.active { 
	background: none;
	color:var(--blue);
	font-weight: 500;
	border-bottom: 1px dashed var(--blue) !important;
}

.playlist-item {
	display: flex;
	background: #F7F7F7;
	width: 100%;
	padding: 10px;
	border-radius: 8px;
}

.playlist-item .item-title {
	color: var(--blue);
	font-size: 18px;
	font-weight: bold;
}

.playlist-details-container {
	border: 1px dashed var(--blue-grey);
	border-radius: 8px;
	padding: 12px;
}

.dot {
	height: 10px;
	width: 10px;
	background-color: var(--blue);
	border-radius: 50%;
	display: inline-block;
}


.screen-item {
	display: flex;
	border-bottom: none !important;
	border-radius: 8px;
	background-color: white;
	color: var(--black);
	padding: 12px;
}

.screen-item.active {
	background-color: var(--light-blue-grey )!important;
	color: var(--blue);
	font-weight: 600 !important;
}

/* Screen Layout */

.screen-layout-type-container {
	height: 70vh;
	background-color: #F4F4F4;
	border-radius: 10px;
	overflow-y: scroll;
}

.screen-layout-type-item {
	width: 160px;
	height: 215px;
	padding: 0px 20px 0px 20px;
	border-radius: 10px;
	border: 2px;
	gap: 10px;
	cursor: pointer;
}

.screen-layout-type-item.active {
	border: 2px solid var(--blue)
}

.screen-layout-menu {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	gap: 14px;
	background-color: var(--white);
	border-radius: 20px;
	height: 40px;
	box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */

	.form-container-left {
		height: fit-content;
	}

	.sidebar-modal {
		width: 100% !important;
	}
}